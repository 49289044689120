import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import _get from 'lodash/get'
import { useSeriesId, useContentType, useSeriesRoot } from '../hooks/seriesHooks'
import { useDispatch } from 'react-redux'
import { useEpisodeId } from '../hooks/episodeHooks'
import {
  seriesCleared,
  seasonCleared,
  episodeCleared,
  fetchPodcastById,
  fetchPodcastEpisodeById,
  setEpisodeLoading,
  fetchSeriesSubmissionsById
} from '../actions'
import AVAILABILITY_STATUSES from '../helpers/availabilityStatuses'
import latestEpisodesFromSeries from '../helpers/latestEpisodesFromSeries'
import FourOhFour from '../../404'
import logger from '../../../logger'
import SERIES_TYPES from '../helpers/seriesTypes'
import { fetchPersonalisation } from '../../../apiClients/personalisation'
import { useIsLoggedIn, useSub } from '../../../components/Auth/AuthContext'

const PageDataByPodcastId = ({ children }) => {
  const [errorStatus, setErrorStatus] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const seriesIdFromState = useSeriesId()
  const episodeIdFromState = useEpisodeId()
  const contentTypeFromState = useContentType()
  const seriesRootFromState = useSeriesRoot()
  const isMounted = useRef(false)
  const isLoggedIn = useIsLoggedIn()
  const userId = useSub()

  useEffect(() => {
    isMounted.current = true
    const signalAborts = []

    const selectFallbackEpisode = series => {
      const latestEpisodes = latestEpisodesFromSeries(series).episodes
      const seriesType = _get(series, 'seriesType')
      const firstSeasonId = _get(series, '_links.seasons[0].name')
      if (latestEpisodes && latestEpisodes.length > 0) {
        // First available episode from latest, if it exist
        const episodeToShow =
          latestEpisodes.find(episode => episode.availability.status === AVAILABILITY_STATUSES.AVAILABLE) ||
          _get(latestEpisodes, '[0]', {})

        if (episodeToShow.episodeId && episodeIdFromState !== episodeToShow.episodeId) {
          dispatch(fetchPodcastEpisodeById(params.seriesId, episodeToShow.episodeId))
        }
      } else if (firstSeasonId && seriesType !== SERIES_TYPES.UMBRELLA) {
        // Redirect to first season in list
        logger.info('No recent episodes. Redirecting to first season')
        history.replace(`/podkast/${params.seriesId}/sesong/${firstSeasonId}${location.search}`)
      }
    }

    const selectEpisode = series => {
      if (isLoggedIn && series.seriesType !== SERIES_TYPES.UMBRELLA) {
        dispatch(setEpisodeLoading(true))
        // Redirect to highlighted program if user is logged in
        const highlightedEpisodeTemplateHref = _get(series, '_links.highlightedEpisode.href')
        const highlightedEpisodeHref = highlightedEpisodeTemplateHref.replace('{userId}', userId)

        const abortController = new AbortController()
        const fetchPersonalisationTimeout = setTimeout(() => {
          abortController.abort()
        }, 3000)
        signalAborts.push(() => {
          clearTimeout(fetchPersonalisationTimeout)
          abortController.abort()
        })

        fetchPersonalisation(highlightedEpisodeHref, { signal: abortController.signal })
          .then(({ episodeId }) => {
            clearTimeout(fetchPersonalisationTimeout)
            const latestEpisodes = latestEpisodesFromSeries(series).episodes
            if (series.seriesType === SERIES_TYPES.STANDARD && latestEpisodes && latestEpisodes.length > 0) {
              const episodeToShow = latestEpisodes.find(episode => episode.episodeId === episodeId)
              if (episodeToShow && episodeToShow.episodeId && episodeToShow.episodeId !== episodeIdFromState) {
                dispatch(fetchPodcastEpisodeById(params.seriesId, episodeToShow.episodeId))
              } else {
                dispatch(setEpisodeLoading(false))
                selectFallbackEpisode(series)
              }
            } else {
              logger.info(`Redirecting to highlighted episode ${episodeId}`)
              dispatch(setEpisodeLoading(false))
              history.replace(`/podkast/${params.seriesId}/${episodeId}${location.search}`)
            }
          })
          .catch(error => {
            // Prevent potential race-conditions if fetchPersonalisation is aborted
            if (isMounted.current) {
              logger.error(`Failed fetching highlighted episode: ${error.toString()}`)
              dispatch(setEpisodeLoading(false))
              selectFallbackEpisode(series)
            }
          })
      } else {
        dispatch(setEpisodeLoading(false))
        selectFallbackEpisode(series)
      }
    }

    if (params.seriesId !== seriesIdFromState || (!!contentTypeFromState && contentTypeFromState !== 'podcast')) {
      dispatch(seriesCleared())
      dispatch(seasonCleared())
      dispatch(episodeCleared())
      dispatch(fetchPodcastById(params.seriesId))
        .loadDataPromise.then(seriesActionRes => {
          selectEpisode(seriesActionRes.payload)
        })
        .catch(error => {
          setErrorStatus(error.status)
        })
      dispatch(fetchSeriesSubmissionsById(params.seriesId))
    } else {
      selectEpisode(seriesRootFromState)
    }

    return () => {
      signalAborts.forEach(abort => {
        abort()
      })
      isMounted.current = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  if (errorStatus) {
    return <FourOhFour />
  }
  return <>{children}</>
}

PageDataByPodcastId.propTypes = {
  children: PropTypes.node
}

export default PageDataByPodcastId
